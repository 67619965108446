<template>
    <div>
      <!-- <v-divider></v-divider> -->
      <v-footer
        padless
       
      >
        <v-layout wrap class="popregular">
          <v-flex xs12>
            <v-card 
              tile
              :height="$vuetify.breakpoint.name == 'xs' ? 'auto' : '60px'"
              flat
              dark
              color="#191612"
            >
              <v-layout wrap justify-center fill-height>
                <v-flex lg2 align-self-center></v-flex>
                <v-flex md1 lg1 align-self-center>
                  <v-layout wrap>
                    <v-flex
                      align-self-center
                      text-center
                      v-for="(item, i) in icons"
                      :key="i"
                    >
                    <a :href="item.route">
                      <v-icon small>{{ item.name }}</v-icon></a>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex lg8 align-self-center>
                  <span style="color: #82827e" class="popregular smallm">
                    WILDLIFE TRUST OF INDIA © 2025 | ALL RIGHTS RESERVED. |
                    DESIGNED & DEVELOPED BY Leopard Tech Labs
                  </span>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-footer>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // images: [
        //   {
        //     file: require("./../../assets/Images/first.jpg"),
        //   },
        //   {
        //     file: require("./../../assets/Images/second.jpg"),
        //   },
        //   {
        //     file: require("./../../assets/Images/third.jpg"),
        //   },
        //   {
        //     file: require("./../../assets/Images/four.jpg"),
        //   },
        //   {
        //     file: require("./../../assets/Images/five.jpg"),
        //   },
        //   {
        //     file: require("./../../assets/Images/six.jpg"),
        //   },
        // ],
        // list: [
        //   { name: "Our Projects", route: "https://www.wti.org.in/our-projects/" },
        //   { name: "Milestones", route: "https://www.wti.org.in/milestones/" },
        //   { name: "Why Us", route: "https://www.wti.org.in/why-us/" },
        //   {
        //     name: "Publications",
        //     route: "https://www.wti.org.in/resource-centre/publications/",
        //   },
        //   {
        //     name: "Refund and Cancellation",
        //     route: "/refundAndCancellation",
        //   },
        //   {
        //     name: "Terms and Conditions",
        //     route: "/termsAndCondition",
        //   },
        //   {
        //     name: "Privacy Policy",
        //     route: "/privacyPolicy",
        //   },
        // ],
        
        icons: [
          {
            name: "mdi-facebook",
            route:
              "https://www.facebook.com/pages/Wildlife-Trust-of-India/112289815480421?fref=ts",
          },
          {
            name: "mdi-linkedin",
            route: "https://in.linkedin.com/company/wildlife-trust-of-india",
          },
          {
            name: "mdi-twitter",
            route:
              "https://www.wti.org.in/job/programme-officer-emergency-relief-network/",
          },
          {
            name: "mdi-youtube",
            route: "https://www.youtube.com/user/wildlifetrustofindia",
          },
          {
            name: "mdi-instagram",
            route: "https://www.instagram.com/wildlifetrustofindia/",
          },
        ],
        // socialMediasList: [
        //   {
        //     image: require("./../assets/images/fb.svg"),
        //     link:"https://www.facebook.com/Speleological-Association-of-India-107212508367437/?ref=pages_you_manage"
        //   },
        //   {
        //     image: require("./../assets/images/insta.png"),
        //     link:"https://instagram.com/speleoindia?utm_medium=copy_link"
        //   },
        //   {
        //     image: require("./../assets/images/twitter.svg"),
        //     link: "https://twitter.com/SpeleoIndia?s=09"
  
        //   },
        // ],
      };
    },
    computed: {
      menus() {
        return this.userNavItems;
      },
    },
    methods: {
      scrollToElement(id) {
        if (this.$route.path !== "/") {
          this.$router.push("/").then(() => {
            document.getElementById(id).scrollIntoView({
              behavior: "smooth",
            });
          });
        } else {
          document.getElementById(id).scrollIntoView({
            behavior: "smooth",
          });
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .bcolor {
    color: #e27826;
  }
  .bcolor:hover {
    color: #ffba00;
  }
  .hovcolor {
    color: white !important;
  }
  .hovcolor:hover {
    color: #e27826 !important;
  }
  </style>
  